// src/components/Layout.jsx
import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import NavBar from "./NavBar";

const Container = styled("div")({
  margin: 0,
  padding: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
});

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3), // Adjust padding as needed
  marginTop: theme.mixins.toolbar.minHeight, // Add margin to account for NavBar height
}));

const Layout = () => {
  return (
    <Container>
      <NavBar />
      <Main>
        <Outlet />
      </Main>
    </Container>
  );
};

export default Layout;
