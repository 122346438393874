// src/components/NavBar.jsx
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useAuthenticator } from "@aws-amplify/ui-react";

const LinkButton = styled(Button)(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
  marginRight: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      <List>
        <ListItem
          button
          component={Link}
          to="/home"
          onClick={toggleDrawer(false)}
        >
          <ListItemText primary="Home" />
        </ListItem>
        {user && (
          <>
            <ListItem
              button
              component={Link}
              to="/about"
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary="About" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/upload"
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary="Upload" />
            </ListItem>
          </>
        )}
        {!user ? (
          <ListItem
            button
            component={Link}
            to="/login"
            onClick={toggleDrawer(false)}
          >
            <ListItemText primary="Login" />
          </ListItem>
        ) : (
          <ListItem
            button
            onClick={() => {
              signOut();
              toggleDrawer(false);
              navigate("/login");
            }}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        )}
      </List>
    </Drawer>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ margin: 0, padding: 0, width: "100%" }}>
        <Toolbar>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component={Link}
                to="/"
                variant="h6"
                sx={{ flexGrow: 1, color: "inherit", textDecoration: "none" }}
              >
                slothdrop.com
              </Typography>
            </>
          ) : (
            <>
              {user && (
                <>
                  <LinkButton component={Link} to="/home">
                    Home
                  </LinkButton>
                  <LinkButton component={Link} to="/about">
                    About
                  </LinkButton>
                  <LinkButton component={Link} to="/upload">
                    Upload
                  </LinkButton>
                </>
              )}
              {!user ? (
                <LinkButton component={Link} to="/login">
                  Login
                </LinkButton>
              ) : (
                <LinkButton onClick={signOut}>Logout</LinkButton>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      {drawer}
    </>
  );
};

export default NavBar;
