// src/views/Upload.js
import React from "react";

const Upload = () => {
  return (
    <div>
      <h1>Upload Page</h1>
      <p>Upload your files here.</p>
    </div>
  );
};

export default Upload;
