// src/views/home/HomeStyle.js
import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const HomeBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "#f5f5f5",
}));

export const TextBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(4),
}));

export const ImgBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(4),
}));

export const Image = styled("img")({
  width: "200px",
  height: "200px",
});
