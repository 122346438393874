// src/views/coming_soon/ComingSoonStyles.js
import { styled } from "@mui/system";
import { Box, Container, Button, Stack, Typography } from "@mui/material";

export const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  padding: theme.spacing(5, 2),
  backgroundColor: "#fff",
  textAlign: "center",
  color: "#000",
}));

export const CustomContainer = styled(Container)(({ theme }) => ({
  maxWidth: "900px",
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  backgroundColor: "#fff",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  "& > *:not(:last-child)": { marginBottom: theme.spacing(2) },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

export const JoinBetaButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: "#007BFF",
  color: "#ffffff",
  fontWeight: "bold",
  fontSize: "1.25rem",
  "&:hover": {
    backgroundColor: "#0056b3",
    boxShadow: `0 0 10px #007BFF`,
  },
  width: "80%",
  maxWidth: "300px",
  borderRadius: theme.shape.borderRadius,
}));

export const SocialMediaContainer = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#000",
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(5),
  },
}));

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: "center",
  width: "100%",
  maxWidth: "80%",
  margin: "auto",
  color: "#000",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));
