import { Amplify } from "aws-amplify";
// import awsConfig from "../aws-exports";

import * as env from "../constants/Environment";

const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: env.USER_POOL_ID,
        userPoolClientId: env.CLIENT_ID,
        identityPoolId: env.IDENTITY_POOL_ID,
        allowGuestAccess: false,
        signUpVerificationMethod: "code",
        region: env.AWS_REGION,
      },
    },
  });
};

export default configureAmplify;
