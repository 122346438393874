// src/App.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import ComingSoon from "./views/coming_soon/ComingSoon";
import Home from "./views/home/Home";
import About from "./views/about/About";
import Upload from "./views/upload/Upload";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import CenteredAuthenticator from "./components/CenteredAuthenticator";

const App = () => {
  return (
    <Authenticator.Provider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ComingSoon />} />
          <Route
            path="login"
            element={
              <CenteredAuthenticator>
                {({ user }) => {
                  console.log("User in /login route:", user);
                  return user ? <Navigate to="/home" /> : null;
                }}
              </CenteredAuthenticator>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="home" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="upload" element={<Upload />} />
          </Route>
        </Route>
      </Routes>
    </Authenticator.Provider>
  );
};

export default App;
