// General Use
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const TARGET_ENV = process.env.REACT_APP_TARGET_ENV; // Not in use

// Cognito
export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN; // Not in use, needed?

// API Gateway
export const GATEWAY_ID = process.env.REACT_APP_GATEWAY_ID;
export const GW_STAGE_NAME = process.env.REACT_APP_GW_STAGE_NAME;

// S3
export const UPLOAD_BUCKET_NAME = process.env.REACT_APP_UPLOAD_BUCKET_NAME;

export const BRANCH_NAME = process.env.REACT_APP_BRANCH_NAME; // Not in use, needed?
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI; // Not in use, needed?
