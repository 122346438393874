// src/views/home/Home.jsx
import React from "react";
import { Typography } from "@mui/material";
import { HomeBox, TextBox, ImgBox, Image } from "./HomeStyle";

function TextArea() {
  const title = "Automate your Instagram management.";
  const body = `
    Save time & boost growth 24/7! Generate captions and automatically post your content at the best time.
    `;
  return (
    <TextBox>
      <Typography variant="h3" align="center">
        {title}
      </Typography>
      <Typography variant="h5" sx={{ m: 3 }} align="center">
        {body}
      </Typography>
    </TextBox>
  );
}

function ImageArea() {
  return (
    <ImgBox>
      <Image src={`${process.env.PUBLIC_URL}/logo512.png`} alt="Logo" />
    </ImgBox>
  );
}

function Home() {
  console.log("Rendering Home component");
  return (
    <HomeBox>
      <TextArea />
      <ImageArea />
    </HomeBox>
  );
}

export default Home;
