import React, { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Link,
  Paper,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
const StepInstructions = ({ children }) => (
  <Paper variant="outlined" sx={{ p: 3, my: 1 }}>
    <Typography>{children}</Typography>
  </Paper>
);

const steps = [
  {
    label: "Instagram Account Setup",
    description: (
      <StepInstructions>
        <Typography>
          Set up your Business Instagram account with the following steps:
        </Typography>
        <Box component="ul" sx={{ pl: 3, my: 2 }}>
          <li>
            If you don't have a Business Instagram account, convert your
            existing one by following the steps in this
            <Link
              href="https://www.youtube.com/watch?v=DG9s9nvK_2U"
              target="_blank"
            >
              {" video."} <VideoCameraFrontIcon />
            </Link>
          </li>
          <li>
            If you already have a Business Instagram account, you're ready for
            the next step.
          </li>
        </Box>
      </StepInstructions>
    ),
  },
  {
    label: "Facebook Business Page Creation",
    description: (
      <StepInstructions>
        <Typography>
          Log into your Facebook account and create a new Business Page. Here
          are the steps to follow:
        </Typography>
        <Box component="ul" sx={{ pl: 3, my: 2 }}>
          <li>Go to Facebook Pages.</li>
          <li>Click on the "Pages" icon on the left to create a new page.</li>
          <li>
            Follow the on-screen instructions to set up your business page,
            including naming your page and adding relevant information.
          </li>
          <li>
            After creating your page, switch to it and select "Meta Business
            Suite" from the options.
          </li>
        </Box>
      </StepInstructions>
    ),
  },
  {
    label: "Connecting Instagram",
    description: (
      <StepInstructions>
        <Typography>
          Within the Meta Business Suite, connect your Business Instagram
          account.
        </Typography>
      </StepInstructions>
    ),
  },
  {
    label: "Setting up Partnership",
    description: (
      <StepInstructions>
        <Typography>
          Within the Meta Business Suite, add slothdrop.com as a partner
        </Typography>

        <Box component="ul" sx={{ pl: 3, my: 2 }}>
          <li>
            In the settings menu of your business page, navigate to
            "Users/Partners.".
          </li>
          <li>
            Add slothdrop.com as a partner by entering the Partner Business ID:{" "}
            <span style={{ fontWeight: "bold" }}>1218787392337654</span>:
          </li>
          <li>
            Assign the necessary access (content and insights) to both your
            Facebook page and Instagram account{" "}
          </li>
        </Box>
      </StepInstructions>
    ),
  },
  {
    label: "Finalising Setup",
    description: (
      <StepInstructions>
        <Typography>
          Complete the setup of your account with the following steps:
        </Typography>
        <Box component="ul" sx={{ pl: 3, my: 2 }}>
          <li>
            Locate your business page ID by clicking 'About' / 'Page
            Transparency' on your page. You'll find the 'Page ID' there.
          </li>
          <li>
            Submit your Facebook page ID and Instagram username into the
            specified fields below.
          </li>
        </Box>
      </StepInstructions>
    ),
  },
];

const GettingStartedGuide = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto" }}>
      <Typography variant="h4" gutterBottom align="center">
        Welcome to SlothDrop{" "}
        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>Beta</span>!
      </Typography>
      <Typography paragraph align="center">
        Follow These Simple Steps to Get Started:
      </Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={
                activeStep > index ? CheckCircleOutlineIcon : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => handleNext(index)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Next Step"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Box sx={{ mt: 2, mb: 1 }}>
          <Typography>
            All steps completed - you&apos;re all set & ready to go!
          </Typography>
          <Button onClick={handleReset}>start Again</Button>
        </Box>
      )}
    </Box>
  );
};

export default GettingStartedGuide;
