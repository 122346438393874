// src/components/CenteredAuthenticator.jsx
import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { styled } from "@mui/material/styles";
import "@aws-amplify/ui-react/styles.css";

const AuthWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "90vh",
  //   paddingTop: "10px",
});

const CenteredAuthenticator = ({ children }) => {
  return (
    <AuthWrapper>
      <Authenticator>{children}</Authenticator>
    </AuthWrapper>
  );
};

export default CenteredAuthenticator;
